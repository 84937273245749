import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { omit } from "lodash";

export interface ICheckoutState {
  Rooms: Array<any>;
  PropertyPagePath: string | null;
}

const initialState: ICheckoutState = {
  Rooms: [],
  PropertyPagePath: null,
};

const checkoutSlice = createSlice({
  name: "checkout",
  initialState,
  reducers: {
    setCheckout: (state: any, { payload }: PayloadAction<any>) => {
      return { ...state, ...payload };
    },
    updateRoom: (state: any, { payload }: PayloadAction<any>) => {
      state.Rooms = { ...state.Rooms, [payload.id]: payload };
      return state;
    },
    removeRoom: (state: any, { payload }: PayloadAction<any>) => {
      state.Rooms = omit(state.Rooms, payload.id);
      return state;
    },
    updateAllRooms: (state: any, { payload }: PayloadAction<any>) => {
      //* This will force the user to re-select all rooms when the user clicks on the breadcrumb of the select room on the checkout page.
      state.Rooms = { ...payload };
      return state;
    },
    setPropertyPagePath: (state: any, { payload }: PayloadAction<any>) => {
      state.PropertyPagePath = payload;
      return state;
    },
  },
});

export const checkoutReducer = checkoutSlice.reducer;
export const {
  setCheckout,
  updateRoom,
  removeRoom,
  updateAllRooms,
  setPropertyPagePath,
} = checkoutSlice.actions;
